/* ----------------- HEADER SECTION ----------------- */

/* Header */
.header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
}

/* ----------------- THE WRITING ----------------- */

/* "Hi, I'm Leah!" */
.hi {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

/* Description */
.sentence {
  margin-top: 0;
  font-size: larger;
}

/* "Software Developer" */
.software-dev {
  color: orange;
}

/* "Leah" */
.leah {
  color: grey;
}

/* ------------------- THE PHOTO ------------------- */

/* Photo Of Me */
#portrait {
  width: 25%;
  margin-left: 5rem;
  border-radius: 0.5rem;
}

/* ------------------- ANIMATION ------------------- */

/* Messages At The End Of Sentence */

/* GRADIENT ANIMATION */
/* .messages {
background: linear-gradient(90deg, grey, #fff, #fff, grey);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 5s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
} */

/* Vertical Flip Animation */
.messages {
  display: inline-flex;
  animation: vertical 5s linear infinite 0s;
  -ms-animation: vertical 5s linear infinite 0s;
  -webkit-animation: vertical 5s linear infinite 0s;
  opacity: 0;
  color: grey;
  overflow: hidden;
  position: relative;
}
/* .messages span:nth-child(2){
	animation-delay: 3s;
	-ms-animation-delay: 3s;
	-webkit-animation-delay: 3s;
}
.messages span:nth-child(3){
	animation-delay: 6s;
	-ms-animation-delay: 6s;
	-webkit-animation-delay: 6s;
} */
@keyframes vertical {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -moz-transform: translateY(0px);
  }
  75% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -moz-transform: translateY(0px);
  }
  95% {
    opacity: 0;
    -webkit-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
  }
  100% {
    opacity: 0;
  }
}

/* -------------- MOBILE RESPONSIVENESS -------------- */

/* For Screens Under 1075 */
@media (max-width: 1075px) {
  #portrait {
    width: 25%;
    height: 10%;
    margin-left: 2rem;
  }
}

/* For Screens Under 900px */
@media (max-width: 900px) {
  .header {
    display: flex;
    flex-direction: column;
  }
  #portrait {
    width: 40%;
    margin-left: 0;
  }
}

/* .messages {
  display: inline-block;
  text-indent: 8px;
  animation: vertical;
  animation-name: vertical;
  animation-duration: 4s;
animation-iteration-count: infinite;
  opacity: 0;
  color: grey;
  overflow: hidden;
} */
