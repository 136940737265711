/* ----------------- RESUME SECTION ----------------- */

/* The Resume Section */
/* .resume-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
} */

/* The Actual Resume */
.resume {
    display: flex;
    justify-content: center;
    align-self: center;
    border-radius: 0.5rem;
    min-height: 100vh;
    width: 100%;
}

/* For Screens Over 2200px */
/* @media (min-width: 2200px) {
    .resume {
        width: 60%;
    };
} */

/* For Screens Over 1800px */
/* @media (min-width: 1800px) {
    .resume {
        width: 80%;
    };
} */

/* For Screens Under 1800px */
/* @media (max-width: 1800px) {
    .resume {
        width: 100%;
    };
} */

/* For Screens Under 950px */
/* @media (max-width: 950px) {
    .resume {
        width: 100%;
    };
} */