/* ----------------- CAPSTONE SECTION ----------------- */

/* The Whole Capstone Section */
.capstone-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5rem;
}

/* Video Of Presentation */
.presentation {
    border-radius: 0.5rem;
    max-width: 100%;
}

/* For Screens Over 2200px */
@media (min-width: 2200px) {
    .presentation {
      width: 60%;
      align-self: center;
    }
  }
  
  /* For Screens Over 1800px */
  @media (min-width: 1800px) {
    .presentation {
      width: 80%;
      align-self: center;
    }
  }
