/* ----------------- PROJECTS SECTION ----------------- */

/* Entire Projects Section */
.projects {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5rem;
}

/* Container That Holds The Cards */
.cards-container {
  display: flex;
  justify-content: center;
}

/* All Of The Cards Together */
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

/* Each Individual Card */
.project-card {
  display: flex;
  flex-direction: column;
  width: 48%;
  border: dotted 2px orange;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

/* Title Above Card */
.card-title {
  margin-bottom: 1rem;
  color: orange;
  text-align: center;
  font-size: medium;
}

/* The Image On Card */
.card-image {
  width: 100%;
  height: 70%;
  border-radius: 0.5rem;
}

/* Accordion Header */
/* .accordion-button {
  color: white;
  background-color: #282c34;
  padding: 1rem;
} */

/* Body Of The Card */
.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-items: center; */
}

/* Text Description Below Card */
.card-description {
  font-size: large;
}

a.button.card-link {
  margin-left: 0;
}

/* Individual Button */
.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.25rem;
  background-color: #282c34;
  border-radius: 0.5rem;
  border-color: rgb(121, 80, 4);
  font-size: medium;
  font-weight: bold;
  text-decoration: none;
  color: white;
  border-style: outset;
  margin-bottom: 1rem;
  cursor: pointer;
  margin-right: 0;
  margin-left: 0;
}

/* When Hovering Over The Button */
.button:hover {
  color: #282c34;
  background-color: orange;
  border-color: #282c34;
}

/* For Screens Over 2200px */
@media (min-width: 2200px) {
  .cards {
    width: 60%;
    align-self: center;
  }
}

/* For Screens Over 1800px */
@media (min-width: 1800px) {
  .cards {
    width: 80%;
    align-self: center;
  }
}

/* For Screens Under 1000px */
@media (max-width: 1000px) {
  .cards {
    justify-content: center;
  }
  .project-card {
    width: 80%;
  }
  /* .button {
    width: 30vw;
  } */
}
