/* ----------------- THANK YOU PAGE ----------------- */

/* Everything On The Page */
.thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

/* 'Thank You' */
.thank-you-header {
    -webkit-text-stroke: 2px #282c34;
	-webkit-text-fill-color: orange;
    font-weight: 900;
    font-size: xxx-large;
    margin: 1rem;
}

/* 'Your Message Has Been Sent' */
.thank-you-message {
    color: white;
    /* text-shadow: 0 0 10px white, 0 0 10px white, 0 0 10px white, 0 0 10px white; */
    font-weight: 400;
    font-size: xx-large;
    margin: 1rem;
}

/* Button */
.thank-you-button {
    padding: 1rem;
    margin: 1rem;
}