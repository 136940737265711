/* ----------------- SOCIALS SECTION ----------------- */

/* The Whole Socials Section */
.socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
}

/* Top Left Logo */
.myLogo {
  width: 5rem;
  height: 5rem;
}

/* The Three Links At Top Right */
.all-links {
  display: flex;
  align-items: center;
}

/* The Resume Button */
.resume-button a{
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: larger;
}

/* Social Media Icons */
.icon {
  width: 3rem;
  margin-left: 4rem;
}

/* Hover For Logos */
.icon:hover {
  filter: invert(100%);
}

/* For Screens Under 900px */
@media (max-width: 900px) {
  .icon {
    margin: 1rem;
  }
}

/* For Screens Under 700px */
@media (max-width: 700px) {
  .myLogo {
    width: 4.5rem;
    height: auto;
  }
  .icon {
    width: 2rem;
  }
}
